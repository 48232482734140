import React, { useContext, useState, useCallback, useEffect } from 'react';

import axios from 'axios';
import Parser from 'ua-parser-js';
import { getCookie, setCookie, Cookie } from "../../utilities/cookie";
import { debounce, throttle } from 'lodash';
// import Router from 'next/router';


export const TrackingContext = React.createContext(undefined);
export const useTracking = () => useContext(TrackingContext);
const baseURL = "https://api.donotpay.com";


export default function Tracking({ children }) {
    const [sessionIdentifier, setSessionIdentifier] = useState(getCookie(Cookie.UserTracking));

    // ============== KEYBOARD EVENTS ==============
    const logKeyPress = (target, selector, sessionID) => {
        const headers = {
            'Content-Type': 'application/json; charset=UTF-8',
        }
        const passableValue = target.value ? target.value : "";
        const url = `${baseURL}/new-user-event-session`;
        const passableSessionID = sessionIdentifier || sessionID;

        axios.post(url, {
            "userSessionIdentifier": passableSessionID,
            "eventType": "INPUT",
            "rawJSON": {
                "selector": selector,
                "text": passableValue,
            },
            "pageURL": window.location.href,
            "pageRefererURL": document.referrer
        }, {
            headers
        }).then((result) => {
            if (result && result.status && result.status === 200) {
                const response = result.data;
                const tempSessionID = response.UserSessionIdentifier || "";
                setSessionIdentifier(tempSessionID)
                setCookie(Cookie.UserTracking, tempSessionID, { expires: 365 });
            }
        }).catch(err => {
            console.log("Error while logging key press ", err);
        });
    }

    const debounceInstanceKeyPress = useCallback(
        debounce((value, selector, sessionID) =>
            logKeyPress(value, selector, sessionID), 750
        ),
        []
    )

    const handleKeyPress = (event, sessionID) => {
        const { target } = event;
        const selector = event?.srcElement?.outerHTML;
        debounceInstanceKeyPress(target, selector, sessionID);
    }


    // ============== MOUSE MOVEMENT EVENTS =========
    const logMouseMovement = (coordinates, sessionID) => {
        const passableSessionID = sessionIdentifier || sessionID;
        const logObject = {
            "userSessionIdentifier": passableSessionID,
            "eventType": "MOUSE",
            "rawJSON": {
                "x": coordinates.x,
                "y": coordinates.y
            },
            "pageURL": window.location.href,
            "pageRefererURL": document.referrer,
        }

        const headers = {
            'Content-Type': 'application/json',
        }

        const url = `${baseURL}/new-user-event-session`;
        axios.post(url, logObject, {
            headers
        }).then((result) => {
            if (result && result.status && result.status === 200) {
                const response = result.data;
                const tempSessionID = response.UserSessionIdentifier || "";
                setSessionIdentifier(tempSessionID)
                setCookie(Cookie.UserTracking, tempSessionID, { expires: 365 });
            }
        }).catch(err => {
            console.log("Error while logging mouse movement ", err);
        });
    }

    const throttleInstanceMouseMovement = useCallback(
        throttle((value, sessionID) =>
            logMouseMovement(value, sessionID),
            1500
        ), []
    )

    const handleMouseMovement = (event, sessionID) => {
        const coordinates = {
            x: event.x,
            y: event.y
        }
        throttleInstanceMouseMovement(coordinates, sessionID)
    }

    // ============== MOUSE CLICK EVENTS ==============
    const logMouseClick = (selector, text, sessionID) => {
        const passableSessionID = sessionIdentifier || sessionID;
        const logObject = {
            "userSessionIdentifier": passableSessionID,
            "eventType": "CLICK",
            "rawJSON": {
                "selector": selector,
                "text": text,
            },
            "pageURL": window.location.href,
            "pageRefererURL": document.referrer,
        }

        const headers = {
            'Content-Type': 'application/json',
        }

        const url = `${baseURL}/new-user-event-session`;
        axios.post(url, logObject, {
            headers
        }).then((result) => {
            if (result && result.status && result.status === 200) {
                const response = result.data;
                const tempSessionID = response.UserSessionIdentifier || "";
                setSessionIdentifier(tempSessionID)
                setCookie(Cookie.UserTracking, tempSessionID, { expires: 365 });
            }
        }).catch(err => {
            console.log("Error while logging mouse click ", err);
        });
    }

    const debounceInstanceMouseClick = useCallback(
        debounce((selector, text, sessionID) =>
            logMouseClick(selector, text, sessionID), 250
        ), []
    )

    const handleClick = (event, sessionID) => {
        const selector = event?.srcElement?.outerHTML;
        const text = event.target.innerText;
        debounceInstanceMouseClick(selector, text, sessionID)
    }

    // =============== INIT SESSION ===============
    function initSession() {
        const trackingId = getCookie(Cookie.UserTracking);
        setSessionIdentifier(trackingId);
        const parser = new Parser.UAParser();
        const userData = parser.getResult();

        const browserObject = {
            "userSessionIdentifier": trackingId || "",
            "userAgent": navigator.userAgent,
            "browser": userData.browser.name,
            "operatingSystem": userData.os.name,
            "innerWidth": `${window.innerWidth}`,
            "innerHeight": `${window.innerHeight}`,
        }

        const headers = {
            'Content-Type': 'application/json',
        }

        const url = `${baseURL}/new-user-session`;
        axios.post(url, browserObject, {
            headers
        }).then((result) => {
            if (result && result.status && result.status === 200) {
                const response = result.data;
                const tempSessionID = response.Identifier || "";
                setSessionIdentifier(tempSessionID)
                setCookie(Cookie.UserTracking, tempSessionID, { expires: 365 });
                // document.addEventListener('keyup', handleKeyPress);
                document.addEventListener('keyup', function (event) {
                    handleKeyPress(event, tempSessionID);
                });
                // leave commented out
                // document.addEventListener('mousemove', function (event) {
                //     handleMouseMovement(event, tempSessionID);
                // });
                document.addEventListener('click', function (event) {
                    handleClick(event, tempSessionID);
                });
            }
        }).catch(err => {
            console.log("Error on ititial load", err);
        });
    }

    useEffect(() => {
        initSession();
    }, [])

    // =============== EXPOSABLE FUNCTIONS ===============
    function resetSession() {
        setCookie(Cookie.UserTracking, "", { expires: 365 });
        initSession();
    }

    function trackCustomEvent(name, json) {
        const logObject = {
            "userSessionIdentifier": sessionIdentifier,
            "eventType": "CUSTOM",
            "rawJSON": {
                "name": name,
                "custom": json || {},
            },
            "pageURL": window.location.href,
            "pageRefererURL": document.referrer,
        }

        const headers = {
            'Content-Type': 'application/json',
        }
        if (sessionIdentifier) {
            const url = `${baseURL}/new-user-event-session`;
            axios.post(url, logObject, {
                headers
            }).then((result) => {
                if (result && result.status && result.status === 200) {
                    const response = result.data;
                    const tempSessionID = response.UserSessionIdentifier || "";
                    setSessionIdentifier(tempSessionID);
                    setCookie(Cookie.UserTracking, tempSessionID, { expires: 365 });
                }
            }).catch(err => {
                console.log("Error while logging custom event ", err);
            });
        }

    }

    return (
        <TrackingContext.Provider
            value={{
                resetSession,
                trackCustomEvent,
            }}
        >
            {children}
        </TrackingContext.Provider>
    );
}

// =============== LOG PAGE VIEW =================
export function logPageView(location) {
    const sessionID = getCookie(Cookie.UserTracking);
    const passableSessionID = sessionID;
    const screenHeight = window ? window.innerHeight : null;
    const screenWidth = window ? window.innerWidth : null;

    const logObject = {
        "userSessionIdentifier": passableSessionID,
        "eventType": "PAGE_LOAD",
        "rawJSON": {
            "innerHeight": `${screenHeight}`,
            "innerWidth": `${screenWidth}`,
        },
        "pageURL": window ? window.location.href : `https://donotpay.com/learn${location}`,
        "pageRefererURL": document.referrer,
    }

    const headers = {
        'Content-Type': 'application/json',
    }

    const url = `${baseURL}/new-user-event-session`;
    axios.post(url, logObject, {
        headers
    }).then((result) => {
        if (result && result.status && result.status === 200) {
            const response = result.data;
            const tempSessionID = response.UserSessionIdentifier || "";
            setCookie(Cookie.UserTracking, tempSessionID, { expires: 365 });
        }
    }).catch(err => {
        console.log("Error while logging page view ", err);
    });
};


// =============== INIT SESSION WHILE IN NODE.JS STACK ===============
export function initSessionForRouting() {
    const trackingId = getCookie(Cookie.UserTracking);
    const parser = new Parser.UAParser();
    const userData = parser.getResult();

    const browserObject = {
        "userSessionIdentifier": trackingId || "",
        "userAgent": navigator.userAgent,
        "browser": userData.browser.name,
        "operatingSystem": userData.os.name,
        "innerWidth": `${window ? window.innerWidth : null}`,
        "innerHeight": `${window ? window.innerHeight : null}`,
    }

    const headers = {
        'Content-Type': 'application/json',
    }

    const url = `${baseURL}/new-user-session`;
    axios.post(url, browserObject, {
        headers
    }).then((result) => {
        if (result && result.status && result.status === 200) {
            const response = result.data;
            const tempSessionID = response.Identifier || "";
            setCookie(Cookie.UserTracking, tempSessionID, { expires: 365 });
            logPageView();
        }
    }).catch(err => {
        console.log("Error on ititial load", err);
    });
}
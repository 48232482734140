import Cookies from 'js-cookie';

export const Cookie = Object.freeze({
    AccessToken: 'access_token',
    UserTokenCode: 'user_token_code',
    CallToActionId: 'call_to_action_id',
    UserTracking: 'dnp_tracking_id'
})

// (key: Cookie, value: string | unknown, options?: unknown)
export const setCookie = (key, value, options) => {
    return Cookies.set(key, value, options);
};

// (key: Cookie): string
export const getCookie = (key) => {
    const cookieValue = Cookies.get(key);

    if (cookieValue) {
        return cookieValue;
    }
    return '';
};

export const deleteCookie = (key) => Cookies.remove(key);

